const SectionOverview = () => {
    return (
        <section className="text-white">
            <div className="container p-4 p-lg-5">
                <div className="row">
                    <div className="col-lg-6 my-auto">
                        <h2 className="fw-bolder mb-5">Overview</h2>
                        <p className="lh-lg">With the theme of “Tech for Innovation”, we are going to bring industry leaders, technology experts, and like-minded innovators together on a whole day of excitement-packed experience, talking about everything from cloud computing, digital intelligence, cutting-edge technologies to business innovation and cooperation, especially on how to effectively expand your business, maximize efficiency and reduce costs simultaneously.</p>
                    </div>
                    <div className="col-lg-6 my-auto">
                        <div className="ratio ratio-16x9">
                            <iframe src="https://www.youtube.com/embed/dE8OJ0gA-zk?rel=0" title="YouTube video" allowFullScreen />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SectionOverview;
