import { faFileLines } from "@fortawesome/free-regular-svg-icons"
import { faGift } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const SectionReward = () => {
    return (
        <>
            <section className="bg-dark text-white">
                <div className="container p-4 p-lg-5">
                    <div className="row">
                        <div className="col-lg-6 my-auto">
                            <h2 className="fw-bolder">Get Free Reward</h2>
                            <p>Come to 2023 Alibaba Summit Event and get the prize</p>
                            <button type="button" className="btn btn-secondary rounded-1 px-3 py-2 disabled" disabled>
                                Registration Closed
                            </button>
                        </div>
                        <div className="col-lg-6 my-auto">
                            <div className="row">
                                <div className="col-md-6 p-3">
                                    <div className="card text-center h-100" style={{ background: '#00000021' }}>
                                        <div className="card-body py-5">
                                            <FontAwesomeIcon color="#7bdcb5" className="mb-3" size="3x" icon={faFileLines} />
                                            <h5>Alibaba Cloud</h5>
                                            <p>ACA Certification Exam</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 p-3">
                                    <div className="card text-center h-100" style={{ background: '#00000021' }}>
                                        <div className="card-body py-5">
                                            <FontAwesomeIcon color="#7bdcb5" className="mb-3" size="3x" icon={faGift} />
                                            <h5>Custom Gifts</h5>
                                            <p>Goodie Bag / T-Shirts</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div
                            className="col-lg-6 text-white order-last order-lg-first"
                            style={{
                                background: `url('/assets/img/bg-4.jpg')`,
                                backgroundPosition: 'center top',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat'
                            }}>
                            <div className="container p-5">
                                <img className="img-fluid" src="/assets/img/img-doorprize.png" alt="Doorprize" />
                            </div>
                        </div>
                        <div className="col-lg-6 bg-warning d-flex flex-column align-items-center justify-content-center text-white">
                            <div className="container p-5">
                                <h2 className="fw-bolder">Doorprize You Will Get</h2>
                                <p>Terms and Conditions :</p>
                                <ul>
                                    <li>Register for the Event</li>
                                    <li>CodePolitan Account Registration</li>
                                    <li>Present at Venues</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SectionReward