import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const SectionCTA = () => {
    return (
        <section className="text-white">
            <div className="container p-4 p-lg-5">
                <div className="card text-center" style={{
                    background: `url('/assets/img/bg-6.jpg')`,
                    backgroundPosition: 'center top',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    borderRadius: '15px'
                }}>
                    <div className="card-body p-5">
                        <p className="display-5 fw-bolder">Get a Seat and Win the Dorprize Now</p>
                        <p className="lh-lg">Don’t miss this exciting event and invite your friends to take part in this event</p>
                        <div className="d-grid gap-2 d-xl-flex my-4 justify-content-center">
                            <button type="button" className="btn btn-secondary rounded-1 px-3 py-2 disabled" disabled>
                                Registration Closed
                            </button>
                            <a className="btn btn-outline-light rounded-1 px-3 py-2" href="https://chat.whatsapp.com/LJKPGuzxPvPHgVBQSHZI8E" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon className="me-2" icon={faWhatsapp} />
                                Any Question?
                            </a>
                        </div>
                    </div>
                </div>

                <div className="text-center my-5">
                    <p>Dipersembahkan oleh:</p>
                    <img className="img-fluid" src="/assets/img/logo-alibaba.png" alt="Alibaba Cloud" />
                </div>
            </div>
        </section>
    )
}

export default SectionCTA