const SectionFeaturedEvents = () => {
    return (
        <section className="text-white">
            <div className="container p-4 p-lg-5">
                <h3 className="h2 fw-bolder mb-5">Featured Events</h3>

                <div id="carouselExample" className="carousel slide">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div className="row">
                                <div className="col">
                                    <img src="/assets/img/events/img-event-1.jpg" className="img-fluid" alt="Event 1" />
                                </div>
                                <div className="col">
                                    <img src="/assets/img/events/img-event-2.jpg" className="img-fluid" alt="Event 1" />
                                </div>
                                <div className="col">
                                    <img src="/assets/img/events/img-event-3.jpg" className="img-fluid" alt="Event 1" />
                                </div>
                                <div className="col">
                                    <img src="/assets/img/events/img-event-4.jpg" className="img-fluid" alt="Event 1" />
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className="row">
                                <div className="col">
                                    <img src="/assets/img/events/img-event-5.jpg" className="img-fluid" alt="Event 1" />
                                </div>
                                <div className="col">
                                    <img src="/assets/img/events/img-event-6.jpg" className="img-fluid" alt="Event 1" />
                                </div>
                                <div className="col">
                                    <img src="/assets/img/events/img-event-1.jpg" className="img-fluid" alt="Event 1" />
                                </div>
                                <div className="col">
                                    <img src="/assets/img/events/img-event-2.jpg" className="img-fluid" alt="Event 1" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true" />
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true" />
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
        </section>
    );
};

export default SectionFeaturedEvents;
