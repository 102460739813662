import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Hero = () => {
    return (
        <section className="text-white text-center" style={{ paddingTop: '80px', paddingBottom: '25em' }}>
            <div className="container py-5 px-4 px-lg-5">
                <div className="row justify-content-center">
                    <div className="col-lg-10 my-auto">
                        <img className="img-fluid h-25 mb-5" src="/assets/img/logo-alibaba-cp.png" alt="Alibaba CP" />
                        <h1 className="title display-1" style={{ letterSpacing: '12px' }}>Tech For Innovation</h1>
                        <p className="title lh-lg mb-4 display-6" style={{ letterSpacing: '5px' }}>2023 Alibaba Cloud Developer Summit</p>
                        <hr className="w-50 d-block mx-auto opacity-100" />
                        <p>Selasa, 10 Januari 2023 | The Ritz-Carlton Jakarta, Pacific Place</p>
                        <div className="d-grid gap-2 d-xl-flex my-4 justify-content-center">
                            <button type="button" className="btn btn-secondary rounded-1 px-3 py-2 disabled" disabled>
                                Registration Closed
                            </button>
                            <a className="btn btn-outline-light rounded-1 px-3 py-2" href="https://chat.whatsapp.com/LJKPGuzxPvPHgVBQSHZI8E" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon className="me-2" icon={faWhatsapp} />
                                Any Question?
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Hero;
