const SectionSchedule = () => {
    return (
        <section
            className="text-center text-white"
            style={{
                background: `url('/assets/img/bg-2.jpg')`,
                backgroundPosition: 'center top',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
            }}>
            <div className="container p-4 p-lg-5">
                <h2 className="fw-bolder">Plenary Session Tech for Innovation</h2>
                <p className="mb-5">10 January 2023 | 9:30 – 12 : 40 WIB</p>

                <img className="img-fluid d-none d-lg-block" src="/assets/img/schedule-1.png" alt="Schedule 1" />
                <img className="img-fluid d-lg-none" src="/assets/img/schedule-2.png" alt="Schedule 2" />
            </div>
        </section>
    );
};

export default SectionSchedule;
