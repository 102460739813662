const SectionTransformation = () => {
    return (
        <section
            className="text-center text-white"
            style={{
                background: `url('/assets/img/bg-3.jpg')`,
                backgroundPosition: 'center top',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
            }}>

            <div className="container d-flex flex-column justify-content-center align-items-center vh-100">
                <h2 className="fw-bolder">Transforming Businesses and Shaping Innovation</h2>
                <p>See how companies large and small are leveraging Alibaba Cloud to grow and improve their business.</p>
            </div>
        </section>
    );
};

export default SectionTransformation;
