import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Hamburger from "hamburger-react";
import { useState } from "react";
import { HashLink } from "react-router-hash-link";
import styles from './Navbar.module.scss';
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const Navbar = () => {
    const [isOpen, setOpen] = useState(false);

    return (
        <nav className="navbar navbar-dark navbar-expand-lg fixed-top" style={{ background: '#032116' }}>
            <div className="container py-2 px-4 px-lg-5">
                <HashLink className="navbar-brand" to="/#top">
                    <img className={styles.img_logo} src="/assets/img/logo-alibaba.png" alt="Alibabacloud" />
                </HashLink>
                <button className="navbar-toggler shadow-0 border-0 p-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <Hamburger color="#fff" size={23} toggled={isOpen} toggle={setOpen} title="menu" />
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mb-2 me-lg-4 mb-lg-0">
                        <li className="nav-item">
                            <a className="nav-link text-white" href="https://chat.whatsapp.com/LJKPGuzxPvPHgVBQSHZI8E" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon className="me-1" icon={faWhatsapp} />
                                Any Question?
                            </a>
                        </li>
                    </ul>
                    <div className="d-grid gap-2 d-md-block">
                        <button type="button" className="btn btn-secondary disabled" disabled>
                            Registration Closed
                        </button>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
