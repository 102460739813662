import { Helmet } from 'react-helmet-async';
import Layout from '../../components/global/Layout/Layout';
import Hero from '../../components/home/Hero/Hero';
import SectionOverview from '../../components/home/SectionOverview/SectionOverview';
import SectionFeaturedEvents from '../../components/home/SectionFeaturedEvents/SectionFeaturedEvents';
import SectionSchedule from '../../components/home/SectionSchedule/SectionSchedule';
import SectionTransformation from '../../components/home/SectionTransformation/SectionTransformation';
import SectionReward from '../../components/home/SectionReward/SectionReward';
import SectionSponsors from '../../components/home/SectionSponsors/SectionSponsors';
import SectionCTA from '../../components/home/SectionCTA/SectionCTA';

const Home = () => {
    return (
        <>
            <Helmet>
                <title>Tech for Innovation - 2023 Alibabacloud Developer Summit</title>
            </Helmet>
            <Layout>
                <div
                    style={{
                        background: `url('/assets/img/bg-1.jpg')`,
                        backgroundPosition: 'center top',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat'
                    }}>
                    <Hero />
                    <SectionOverview />
                    <SectionFeaturedEvents />
                </div>
                <SectionSchedule />
                <SectionTransformation />
                <SectionReward />
                <div
                    style={{
                        background: `url('/assets/img/bg-5.jpg')`,
                        backgroundPosition: 'center top',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat'
                    }}>
                    <SectionSponsors />
                    <SectionCTA />
                </div>
            </Layout>
        </>
    );
};

export default Home;
