const Footer = () => {
    return (
        <footer>
            <div className="text-center text-white" style={{ background: '#032116' }}>
                <div className="container py-3 px-5">
                    <p className="mb-0">&copy; 2022 - Codepolitan. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
